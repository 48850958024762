<template>
  <div class="user-help">
    <h2>Pro-Tekt GPS Tracking Help</h2>
    <ul>
      <li><a href="#heading-1">1. Basic Tracking</a></li>
      <li><a href="#heading-2">2. Fleet Screen</a></li>
      <li><a href="#heading-3">3. Subscriptions</a></li>
      <li><a href="#heading-4">4. Sharing Devices</a></li>
      <li><a href="#heading-5">5. Notification Rules</a></li>
      <li><a href="#heading-6">6. FAQ</a></li>
    </ul>
    <h3><a id="heading-1"/>1. Basic Tracking</h3>
    <p>
      <strong>Pro-Tekt Basic Tracking</strong> allows users to view the location and trip history of their
      vehicles in the Pro-Tekt App. In order to view vehicle information, users will need to download and
      install the Pro-Tekt App for either Android or iPhone. Direct links to the app can be found in the
      <router-link :to="{ path: 'preferences', query: { tab: 'app-download'}}">'App Download'</router-link> tab of the
      <router-link to="preferences">
        User Preferences <i :class="$config.icons.nav.preferences"/> page.
      </router-link>
    </p>
    <h3><a id="heading-2"/>2. Fleet Screen</h3>
    <p>
      The
      <router-link to="/">
        Pro-Tekt Fleet Screen <i :class="$config.icons.nav.fleet"/>
      </router-link> provides greater access to GPS tracking information. It
      includes a map view that shows the current location of all devices that the user has access to as well
      as access to the
      <router-link to="stats">
        Stats Reporting <i :class="$config.icons.nav.stats"/> page.
      </router-link>
    </p>
    <p>
      Fleet screen access is obtained by purchasing a 'Fleet Screen' subscription, instead of a 'Basic Tracking'
      subscription from the <router-link :to="{ path: 'preferences', query: { tab: 'subscription'}}">Subscription
    </router-link> tab of the
      <router-link to="preferences">
        User Preferences <i :class="$config.icons.nav.preferences"/> page.
      </router-link>
    </p>
    <h3><a id="heading-3"/>3. Subscriptions</h3>
    <p>
      Users who own devices need to have an active subscription in order to access their GPS tracking services. If the
      your devices are coming up as RED in the Pro-Tekt App then your subscription has expired or not been set up yet.
      Subscriptions are automatically charged at the end of the billing period, based on the number of devices that the
      user owns. You can view your current subscription details under the
      <router-link :to="{ path: 'preferences', query: { tab: 'subscription'}}">Subscription
      </router-link> tab of the
      <router-link to="preferences">
        User Preferences <i :class="$config.icons.nav.preferences"/> page.
      </router-link>
    </p>
    <p>Note: Users who have had devices shared with them do not require their own subscription. Only the owner of the
      device needs to be subscribed.</p>

    <h3><a id="heading-4"/>4. Sharing Devices</h3>
    <p>
      GPS Devices can be shared with other Protekt users. This will allow other users to see the status of your vehicle
      and access trip data. Users can be added from either the 'Share' button in the Pro-Tekt Mobile App or for Fleet
      Screen users, via the 'Share Device' button on the
      <router-link to="/">
        Pro-Tekt Fleet Screen <i :class="$config.icons.nav.fleet"/>
      </router-link>
    </p>
    <p>
      Shares are created based on email addresses. If a user does not currently have a Pro-Tekt account when a device is
      shared with them, an account will automatically be created and an email will be sent to them containing an access
      code.
    </p>
    <p> Devices can be shared with either Write Access or Read-Only Access. In the case of read-only access, users who
      have been given access to the device will be able to see device properties, trips, and alarms, but not change any
      of the device's details.
    </p>
    <h3><a id="heading-5"/>5. Notification Rules</h3>
    <p>
      Notification Rules control how and when you receive notifications from the Pro-Tekt system about your vehicles.
      The Notification Rules tab in <router-link to="preferences">
      User Preferences <i :class="$config.icons.nav.preferences"/>
    </router-link>
      page can be used to modify these settings. Rules are followed in order of decreasing specificity, this means that
      that a more specific notification rule, will take precedence over a less specific rule. e.g if you have a rule
      configured to send an email notification for Overspeed Alarms on 'Vehicle A' and a rule set to send push
      notifications for all vehicles (in the Default Category), when an Overspeed event occurs for 'Vehicle A' an email
      will be sent, as the rule for only 'Vehicle A' is more specific than the default rule set to send a push message.
    </p>
    <h4>5.1 Notification Types</h4>
    <p>
      Currently there are four different notification types available. These determine what method will be used to send
    a notification for an event.
      <ol>
        <li><strong>Email</strong> - Email Notifications are sent via Email to the address registered with your account
        </li>
        <li><strong>Push</strong> - Push Notifications are sent via iOS/Android Push to any device that you have
          installed the Pro-Tekt mobile app on.
        </li>
        <li><strong>Web</strong> - Web Notifications are only shown via the Pro-Tekt Website.
        </li>
        <li><strong>None</strong> - No Notifications will be sent when this rule is being followed.
        </li>
      </ol>
    </p>
    <h4>5.2 Event Types</h4>
    <p>
      Events are our way of recording when things happen to vehicles. There are a large number of different events,
    which cover everything from Speeding to filling up with fuel. Most events are generated by the system when the
    vehicle reports that something has happened to it, but it's also possible for  <router-link to="/">
      Pro-Tekt Fleet Screen <i :class="$config.icons.nav.fleet"/>
    </router-link> users to add events to vehicles to track things like accidents, fuel stops and vehicle services.
    Event Types are divided into four broad categories:
    <ol>
      <li><strong>Vehicle Status</strong> - Events related to the physical state of the vehicle and the Pro-Tekt
        Tracking device installed in it. e.g. Low Battery Voltage, Tamper Alarm, etc
      </li>
      <li><strong>Location Events</strong> - Events related to the location of the vehicle e.g. Geolimit IN and OUT
      </li>
      <li><strong>Driving Events</strong> - Events related to the way the vehicle is being driven e.g. Overspeed Alarms,
        Harsh Braking or Acceleration, etc.
      </li>
      <li><strong>User Events</strong> - Events added via the Fleet Screen by users. e.g. Vehicle Service events, Fuel
        stops.
      </li>
    </ol>
    </p>
    <p> When working with Notification Rules, it's important to remember that more specific rules will take precedence
      over less specific rules and this applies to Event Types too. A rules created against 'All Driving Events' will be
      overridden by a rules created against a specific driving event. e.g. if a rule exists for event type 'Overspeed
      Alarm', it will be used instead of a rule created for 'All Driving Events', when an Overspeed event occurs.
    </p>
    <h4>5.3 Default Rules Category</h4>
    <p>
      The <strong>Default Rules</strong> category is used to create rules which will apply to all vehicles that a user
      has access to. Note that rules for specific vehicles, will always take precedence over rules added to the default
      category.
    </p>
    <h4>5.4 Vehicle Rules Categories</h4>
    <p>
      Each vehicle that a user has access to will appear as a category in the Notification Rules tab. To expand the tab,
      simple click on the caret icon (<i :class="$config.icons.twisties.collapsed"/>) or the name of the category. Rules
      created in a vehicle category will apply only to the vehicle that category is for.
    </p>
    <h4>5.5 Adding Notification Rules</h4>
    <p>
      Use the 'Add Rule' <i :class="$config.icons.general.add"/> button on the category heading to create a Notification
      Rule for that category. You can then select the Event Type and Notification Type for the rule and click
      <strong>Save</strong> to finalise the rule creation.
    </p>
    <h4>5.6 Removing Notification Rules</h4>
    <p>
      Use the 'Remove/Reset Rule' <i :class="$config.icons.general.remove"/> button located at the far right side of
      each rule to remove the rule if it is no longer required.
    </p>
    <h4>5.7 Resetting Notification Rules</h4>
    <p>
      In the upper-right corner of the Notification Rules tab there is a button labelled <strong>Reset All</strong>,
      which will REMOVE all custom notification rules and reset your notifications to default. Please note that we
      cannot reverse this action and if you accidentally remove custom rules you have created, you will need to create
      them again yourself.
    </p>
    <h3><a id="heading-6"/>6. Frequently Asked Questions</h3>
    <p class="question">Q. I keep seeing references to the 'Fleet Screen', but I can't see it?</p>
    <p>
      A. Only users with a 'Fleet Screen' subscription can access the Pro-Tekt website.
    </p>
    <p class="question">Q. My Devices in the Pro-Tekt App are coming up as Red and I can't see their information?</p>
    <p>
      A. Your subscription has expired or has not been set up yet. Go to the
      <router-link :to="{ path: 'preferences', query: { tab: 'subscription'}}">
        Subscription
      </router-link> tab of the
      <router-link to="preferences">
        User Preferences <i :class="$config.icons.nav.preferences"/> page.
      </router-link>
      to fix your subscription.
    </p>
    <p class="question">Q. One of the shared devices in my list is coming up as red?</p>
    <p>
      A. The person who has shared the device with you no longer has a valid subscription. If the owner of a device does
      not have a valid subscription, you will not be able to access the device's information.
    </p>
  </div>
</template>

<script>

export default {
  name: 'user-help',
  components: {},
  data: function () {
    return {
    }
  },
  created () {
  },
  methods: { }
}
</script>

<style scoped lang="scss">
@import '../variables';

.user-help {
  color: $text-color-invert;
  text-align: start;
}

strong {
  color: $theme-color-primary-3;
}

h3 {
  margin-top: 2em;
}
h4 {
  margin-top: 1em;
}
ol {
  margin-top: 0.5em;
}
i {
  color: $theme-color-primary-3;
}
.question {
  font-style: italic;
  margin-bottom: 0.5em;
  margin-top: 1.5em;
}
</style>
