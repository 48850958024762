var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-api-access" },
    [
      _c("LoadingBox", { attrs: { loading: _vm.loading } }),
      !_vm.loading
        ? _c("div", {}, [
            _c(
              "div",
              {},
              [
                _c(
                  "label",
                  {
                    staticClass: "field-label",
                    attrs: { for: "product_name" }
                  },
                  [_vm._v("OAuth2 API Access")]
                ),
                _vm.oauthTokens.length > 0
                  ? _c("b-table", {
                      attrs: {
                        striped: "",
                        hover: "",
                        items: _vm.oauthTokens,
                        fields: _vm.oauthFields
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(delete)",
                            fn: function(row) {
                              return [
                                _c("div", { staticClass: "row" }, [
                                  !row.item.disable_delete
                                    ? _c(
                                        "i",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true }
                                            }
                                          ],
                                          staticClass:
                                            "icon-button row-icon-button-danger",
                                          class:
                                            _vm.$config.icons.general.remove,
                                          attrs: { title: "Remove/Reset" },
                                          on: {
                                            click: function($event) {
                                              return _vm.revokeClient(row.item)
                                            }
                                          }
                                        },
                                        [_vm._v(" Revoke Key")]
                                      )
                                    : _vm._e()
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        222901746
                      )
                    })
                  : _vm._e(),
                _vm.oauthTokens.length === 0
                  ? _c("div", [
                      _vm._v(
                        "You do not have any clients set up yet. Set up one using the form below."
                      )
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "col field-col" }, [
                  _c(
                    "label",
                    {
                      staticClass: "field-label",
                      attrs: { for: "auth_token" }
                    },
                    [_vm._v("Add New Client")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newClientID,
                        expression: "newClientID"
                      }
                    ],
                    staticClass: "text-field w-input",
                    attrs: {
                      type: "text",
                      maxlength: "256",
                      placeholder: "Client ID",
                      id: "auth_token",
                      autocomplete: "off"
                    },
                    domProps: { value: _vm.newClientID },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.newClientID = $event.target.value
                      }
                    }
                  })
                ]),
                _c(
                  "div",
                  { staticClass: "col field-col" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "button",
                        on: {
                          click: function($event) {
                            return _vm.addClient()
                          }
                        }
                      },
                      [_vm._v("Add Client")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "div",
        {},
        [
          _c(
            "label",
            { staticClass: "field-label", attrs: { for: "webhook_table" } },
            [_vm._v("Webhook Notifications")]
          ),
          _vm.webhooks.length > 0
            ? _c("b-table", {
                attrs: {
                  striped: "",
                  hover: "",
                  items: _vm.webhooks,
                  fields: _vm.webhookFields,
                  id: "webhook_table"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(actions)",
                      fn: function(row) {
                        return [
                          _c("div", [
                            _c("i", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              staticClass: "icon-button",
                              class: _vm.$config.icons.general.edit,
                              attrs: { title: "Edit Webhook" },
                              on: {
                                click: function($event) {
                                  return _vm.editWebhook(row.item)
                                }
                              }
                            }),
                            _c("i", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              staticClass: "icon-button",
                              class: _vm.$config.icons.general.sendNotification,
                              attrs: { title: "Send Test Webhook" },
                              on: {
                                click: function($event) {
                                  return _vm.sendTest(row.item)
                                }
                              }
                            }),
                            !row.item.disable_delete
                              ? _c("i", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true }
                                    }
                                  ],
                                  staticClass:
                                    "icon-button row-icon-button-danger",
                                  class: _vm.$config.icons.general.remove,
                                  attrs: { title: "Remove/Reset" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteWebhook(row.item)
                                    }
                                  }
                                })
                              : _vm._e()
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1760419090
                )
              })
            : _vm._e(),
          _vm.webhooks.length === 0
            ? _c("div", [
                _vm._v(
                  "You do not have any webhooks set up yet. Set up one using the form below."
                )
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "col field-col" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.addWebhook()
                    }
                  }
                },
                [_vm._v("Add Webhook")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-webhook-edit",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Command Help"
          }
        },
        [
          _c("edit-webhook", {
            attrs: {
              "modal-id": "modal-webhook-edit",
              webhook: _vm.selectWebhook
            },
            on: { save: _vm.loadWebhooks }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }