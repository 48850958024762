<template>
  <div class="edit-webhook">
    <LoadingBox :loading="loading"></LoadingBox>
    <div class="field-container" v-if="!loading">
      <div class="col-12 field-col" :class="{ 'form-error': $v.webhookInternal.webhook_url.$error }">
        <label for="webhook_url" class="field-label">Webhook URL
          <i class="icon-button" :class="$config.icons.general.info"
             v-b-tooltip title="Enter the URL to be accessed when sending the webhook data."
          ></i>
        </label>
        <input type="text" v-model="$v.webhookInternal.webhook_url.$model" class="text-field w-input"
               maxlength="256" placeholder="http://example.com/blah" id="webhook_url">
        <div class="input-error" v-if="!$v.webhookInternal.webhook_url.required">Field is Required</div>
      </div>
      <div class="col field-col" :class="{ 'form-error': $v.webhookInternal.status.$error }">
        <label for="webhook_type" class="field-label">Webhook Status
        </label>
        <b-form-select class="text-field w-input" :options="webhookStatusOptions"
                       v-model="$v.webhookInternal.status.$model"
                       maxlength="256" placeholder="Webhook Type" id="webhook_type" />
        <div class="input-error" v-if="!$v.webhookInternal.status.required">Field is Required</div>
      </div>
      <div class="col field-col" :class="{ 'form-error': $v.webhookInternal.webhook_type.$error }">
        <label for="webhook_type" class="field-label">Webhook Type
        </label>
        <b-form-select class="text-field w-input" :options="webhookTypeOptions"
                       v-model="$v.webhookInternal.webhook_type.$model"
               maxlength="256" placeholder="Webhook Type" id="webhook_type" />
        <div class="input-error" v-if="!$v.webhookInternal.webhook_type.required">Field is Required</div>
      </div>
      <div class="col-12 field-col" :class="{ 'form-error': $v.webhookInternal.config.headers.$error }">
        <label for="webhook_headers" class="field-label">Custom Headers
          <i class="icon-button" :class="$config.icons.general.info"
             v-b-tooltip title="Static JSON Data to be included in the headers when sending the webhook."
          ></i>
        </label>
        <input type="text" v-model="$v.webhookInternal.config.headers.$model" class="text-field w-input"
               maxlength="256" placeholder="Custom Headers" id="webhook_headers">
        <div class="input-error" v-if="!$v.webhookInternal.config.headers.isJson">Must be valid JSON</div>
      </div>

      <div class="col field-col">
        <label for="webhook_exclude_devices" class="field-label">Exclude Devices Type
          <i class="icon-button" :class="$config.icons.general.info"
             v-b-tooltip title="Select any devices that should be EXCLUDED from the webhook events."
          ></i>
        </label>
        <div class="checkbox-container">
          <b-form-checkbox-group type="text" v-model="webhookInternal.config.device_blacklist" class="text-field w-input"
                                 :options="deviceOptions">
          </b-form-checkbox-group>
        </div>
      </div>

      <div class="col field-col" >
        <label for="webhook_exclude_event_types" class="field-label">Exclude Event Type
          <i class="icon-button" :class="$config.icons.general.info"
             v-b-tooltip title="Select any event types that should be EXCLUDED from the webhooks being sent."
          ></i>
        </label>
        <div class="checkbox-container">
          <b-form-checkbox-group type="text" v-model="webhookInternal.config.event_type_blacklist" class="text-field w-input"
                           :options="eventOptions"
                 maxlength="256" placeholder="Custom Headers" id="webhook_exclude_event_types" />
        </div>
      </div>
    </div>

    <div class="footer">
      <b-button class="button" @click="clickCancel">Cancel</b-button>
      <b-button class="button" @click="clickSave">Save</b-button>
    </div>
  </div>
</template>

<script>
import * as DataProvider from './helpers/DataProvider'
import * as ErrorHelper from './helpers/ErrorHelper'
import { isJSON } from '@/components/helpers/customValidators'
import LoadingBox from './helpers/LoadingBox'
import { required } from 'vuelidate/lib/validators'
import * as AlertHelper from '@/components/helpers/AlertHelper'

export default {
  name: 'edit-webhook',
  components: { LoadingBox },
  props: {
    modalId: String,
    webhook: Object
  },
  validations: {
    webhookInternal: {
      webhook_url: {
        required
      },
      status: {
        required
      },
      webhook_type: {
        required
      },
      config: {
        headers: {
          isJSON: isJSON
        }
      }
    }
  },
  data: function () {
    return {
      loading: true,
      webhookInternal: {
        id: -1,
        webhook_url: '',
        webhook_type: 0,
        status: 0,
        config: {
          headers: '',
          event_type_blacklist: [],
          device_blacklist: []
        }
      },
      webhookTypeOptions: [
        {
          value: 0,
          text: 'Custom'
        },
        {
          value: 1,
          text: 'Events'
        }
      ],
      deviceOptions: [
        {
          value: 0,
          text: 'Text'
        }
      ],
      webhookStatusOptions: [
        {
          value: 0,
          text: 'Disabled'
        },
        {
          value: 1,
          text: 'Active'
        },
        {
          value: 2,
          text: 'Disabled (Error)',
          disabled: true
        }
      ],
      eventOptions: []
    }
  },

  async created () {
    console.log(this.webhook)
    if (this.webhook) {
      this.webhookInternal = { ...this.webhook }
      if (this.webhookInternal.config.headers) {
        this.webhookInternal.config.headers = JSON.stringify(this.webhookInternal.config.headers)
      }
    }
    this.deviceOptions = await this.getDevices()
    this.eventOptions = await this.getEventTypes()
    this.loading = false
  },
  methods: {
    getDevices: async function () {
      let userData = await this.$auth.getUserData()
      let devices = Object.keys(userData.devices)
      devices = devices.concat(Object.keys(userData.shared_devices))

      return devices.map((x) => { return { value: x, text: x } })
    },
    getEventTypes: async function () {
      let eventData = await this.$eventService.getEventTypeOpts()
      let eventTypes = []
      eventData.forEach((x) => { eventTypes = eventTypes.concat(x.options) })
      return eventTypes
    },
    clickSave: async function () {
      console.log(this.webhookInternal)
      if (this.$v.$anyError) {
        ErrorHelper.displayGeneralErrorToast('Some Fields Contain Invalid Data. Please fix them.', 'Invalid Fields')
        return
      }
      let resp
      // New Webhook
      if (this.webhookInternal.id === -1) {
        resp = await DataProvider.addWebhook(
          this.webhookInternal.webhook_url,
          this.webhookInternal.webhook_type,
          this.webhookInternal.config.headers,
          this.webhookInternal.config.device_blacklist,
          this.webhookInternal.config.event_type_blacklist,
          this.webhookInternal.status
        )
      } else { // Update Webhook
        resp = await DataProvider.updateWebhook(
          this.webhookInternal.id,
          this.webhookInternal.webhook_url,
          this.webhookInternal.webhook_type,
          this.webhookInternal.config.headers,
          this.webhookInternal.config.device_blacklist,
          this.webhookInternal.config.event_type_blacklist,
          this.webhookInternal.status
        )
      }

      if (resp.success) {
        AlertHelper.successToast('Changes Saved!', 'Your changes were successfully saved.')
        this.webhookInternal.id = resp.id
        this.$emit('save')
        this.$bvModal.hide(this.modalId)
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    clickCancel: async function () {
      if (this.$v.$anyDirty) {
        let res = await this.$bvModal.msgBoxConfirm('You have unsaved changes, are you sure you want to close this window?', {
          title: 'Confirm Discard Changes',
          okVariant: 'warning',
          centered: true
        })
        if (!res) {
          return
        }
      }
      this.$bvModal.hide(this.modalId)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../variables";

.edit-webhook {
  color: $text-color-invert;
  padding: 5px;
}

.button {
  margin: 0px 5px;
}

.field-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.field-col {
  min-width: fit-content;
}

.field-label {
  font-family: "Open Sans", sans-serif;
  color: $theme-color-primary-3;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
}

.checkbox-container {
  background: $theme-color-background-5;
  max-height: 4em;
  overflow-y: auto;
}

.footer {
  width: 100%;
  bottom: 2%;
  display: flex;
  justify-content: flex-end;
  margin: 20px 1% 0 1%;
  flex-wrap: wrap;
}

@media screen and (max-width: 600px) {
  .footer {
    justify-content: space-around;
    margin: 0;
  }
  .button {
    margin-top: 5%;
  }
}
</style>
