var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-help" }, [
    _c("h2", [_vm._v("Pro-Tekt GPS Tracking Help")]),
    _vm._m(0),
    _vm._m(1),
    _c(
      "p",
      [
        _c("strong", [_vm._v("Pro-Tekt Basic Tracking")]),
        _vm._v(
          " allows users to view the location and trip history of their vehicles in the Pro-Tekt App. In order to view vehicle information, users will need to download and install the Pro-Tekt App for either Android or iPhone. Direct links to the app can be found in the "
        ),
        _c(
          "router-link",
          {
            attrs: {
              to: { path: "preferences", query: { tab: "app-download" } }
            }
          },
          [_vm._v("'App Download'")]
        ),
        _vm._v(" tab of the "),
        _c("router-link", { attrs: { to: "preferences" } }, [
          _vm._v(" User Preferences "),
          _c("i", { class: _vm.$config.icons.nav.preferences }),
          _vm._v(" page. ")
        ])
      ],
      1
    ),
    _vm._m(2),
    _c(
      "p",
      [
        _vm._v(" The "),
        _c("router-link", { attrs: { to: "/" } }, [
          _vm._v(" Pro-Tekt Fleet Screen "),
          _c("i", { class: _vm.$config.icons.nav.fleet })
        ]),
        _vm._v(
          " provides greater access to GPS tracking information. It includes a map view that shows the current location of all devices that the user has access to as well as access to the "
        ),
        _c("router-link", { attrs: { to: "stats" } }, [
          _vm._v(" Stats Reporting "),
          _c("i", { class: _vm.$config.icons.nav.stats }),
          _vm._v(" page. ")
        ])
      ],
      1
    ),
    _c(
      "p",
      [
        _vm._v(
          " Fleet screen access is obtained by purchasing a 'Fleet Screen' subscription, instead of a 'Basic Tracking' subscription from the "
        ),
        _c(
          "router-link",
          {
            attrs: {
              to: { path: "preferences", query: { tab: "subscription" } }
            }
          },
          [_vm._v("Subscription ")]
        ),
        _vm._v(" tab of the "),
        _c("router-link", { attrs: { to: "preferences" } }, [
          _vm._v(" User Preferences "),
          _c("i", { class: _vm.$config.icons.nav.preferences }),
          _vm._v(" page. ")
        ])
      ],
      1
    ),
    _vm._m(3),
    _c(
      "p",
      [
        _vm._v(
          " Users who own devices need to have an active subscription in order to access their GPS tracking services. If the your devices are coming up as RED in the Pro-Tekt App then your subscription has expired or not been set up yet. Subscriptions are automatically charged at the end of the billing period, based on the number of devices that the user owns. You can view your current subscription details under the "
        ),
        _c(
          "router-link",
          {
            attrs: {
              to: { path: "preferences", query: { tab: "subscription" } }
            }
          },
          [_vm._v("Subscription ")]
        ),
        _vm._v(" tab of the "),
        _c("router-link", { attrs: { to: "preferences" } }, [
          _vm._v(" User Preferences "),
          _c("i", { class: _vm.$config.icons.nav.preferences }),
          _vm._v(" page. ")
        ])
      ],
      1
    ),
    _c("p", [
      _vm._v(
        "Note: Users who have had devices shared with them do not require their own subscription. Only the owner of the device needs to be subscribed."
      )
    ]),
    _vm._m(4),
    _c(
      "p",
      [
        _vm._v(
          " GPS Devices can be shared with other Protekt users. This will allow other users to see the status of your vehicle and access trip data. Users can be added from either the 'Share' button in the Pro-Tekt Mobile App or for Fleet Screen users, via the 'Share Device' button on the "
        ),
        _c("router-link", { attrs: { to: "/" } }, [
          _vm._v(" Pro-Tekt Fleet Screen "),
          _c("i", { class: _vm.$config.icons.nav.fleet })
        ])
      ],
      1
    ),
    _c("p", [
      _vm._v(
        " Shares are created based on email addresses. If a user does not currently have a Pro-Tekt account when a device is shared with them, an account will automatically be created and an email will be sent to them containing an access code. "
      )
    ]),
    _c("p", [
      _vm._v(
        " Devices can be shared with either Write Access or Read-Only Access. In the case of read-only access, users who have been given access to the device will be able to see device properties, trips, and alarms, but not change any of the device's details. "
      )
    ]),
    _vm._m(5),
    _c(
      "p",
      [
        _vm._v(
          " Notification Rules control how and when you receive notifications from the Pro-Tekt system about your vehicles. The Notification Rules tab in "
        ),
        _c("router-link", { attrs: { to: "preferences" } }, [
          _vm._v(" User Preferences "),
          _c("i", { class: _vm.$config.icons.nav.preferences })
        ]),
        _vm._v(
          " page can be used to modify these settings. Rules are followed in order of decreasing specificity, this means that that a more specific notification rule, will take precedence over a less specific rule. e.g if you have a rule configured to send an email notification for Overspeed Alarms on 'Vehicle A' and a rule set to send push notifications for all vehicles (in the Default Category), when an Overspeed event occurs for 'Vehicle A' an email will be sent, as the rule for only 'Vehicle A' is more specific than the default rule set to send a push message. "
        )
      ],
      1
    ),
    _c("h4", [_vm._v("5.1 Notification Types")]),
    _vm._m(6),
    _c("h4", [_vm._v("5.2 Event Types")]),
    _c(
      "p",
      [
        _vm._v(
          " Events are our way of recording when things happen to vehicles. There are a large number of different events, which cover everything from Speeding to filling up with fuel. Most events are generated by the system when the vehicle reports that something has happened to it, but it's also possible for "
        ),
        _c("router-link", { attrs: { to: "/" } }, [
          _vm._v(" Pro-Tekt Fleet Screen "),
          _c("i", { class: _vm.$config.icons.nav.fleet })
        ]),
        _vm._v(
          " users to add events to vehicles to track things like accidents, fuel stops and vehicle services. Event Types are divided into four broad categories: "
        ),
        _vm._m(7)
      ],
      1
    ),
    _c("p", [
      _vm._v(
        " When working with Notification Rules, it's important to remember that more specific rules will take precedence over less specific rules and this applies to Event Types too. A rules created against 'All Driving Events' will be overridden by a rules created against a specific driving event. e.g. if a rule exists for event type 'Overspeed Alarm', it will be used instead of a rule created for 'All Driving Events', when an Overspeed event occurs. "
      )
    ]),
    _c("h4", [_vm._v("5.3 Default Rules Category")]),
    _vm._m(8),
    _c("h4", [_vm._v("5.4 Vehicle Rules Categories")]),
    _c("p", [
      _vm._v(
        " Each vehicle that a user has access to will appear as a category in the Notification Rules tab. To expand the tab, simple click on the caret icon ("
      ),
      _c("i", { class: _vm.$config.icons.twisties.collapsed }),
      _vm._v(
        ") or the name of the category. Rules created in a vehicle category will apply only to the vehicle that category is for. "
      )
    ]),
    _c("h4", [_vm._v("5.5 Adding Notification Rules")]),
    _c("p", [
      _vm._v(" Use the 'Add Rule' "),
      _c("i", { class: _vm.$config.icons.general.add }),
      _vm._v(
        " button on the category heading to create a Notification Rule for that category. You can then select the Event Type and Notification Type for the rule and click "
      ),
      _c("strong", [_vm._v("Save")]),
      _vm._v(" to finalise the rule creation. ")
    ]),
    _c("h4", [_vm._v("5.6 Removing Notification Rules")]),
    _c("p", [
      _vm._v(" Use the 'Remove/Reset Rule' "),
      _c("i", { class: _vm.$config.icons.general.remove }),
      _vm._v(
        " button located at the far right side of each rule to remove the rule if it is no longer required. "
      )
    ]),
    _c("h4", [_vm._v("5.7 Resetting Notification Rules")]),
    _vm._m(9),
    _vm._m(10),
    _c("p", { staticClass: "question" }, [
      _vm._v(
        "Q. I keep seeing references to the 'Fleet Screen', but I can't see it?"
      )
    ]),
    _c("p", [
      _vm._v(
        " A. Only users with a 'Fleet Screen' subscription can access the Pro-Tekt website. "
      )
    ]),
    _c("p", { staticClass: "question" }, [
      _vm._v(
        "Q. My Devices in the Pro-Tekt App are coming up as Red and I can't see their information?"
      )
    ]),
    _c(
      "p",
      [
        _vm._v(
          " A. Your subscription has expired or has not been set up yet. Go to the "
        ),
        _c(
          "router-link",
          {
            attrs: {
              to: { path: "preferences", query: { tab: "subscription" } }
            }
          },
          [_vm._v(" Subscription ")]
        ),
        _vm._v(" tab of the "),
        _c("router-link", { attrs: { to: "preferences" } }, [
          _vm._v(" User Preferences "),
          _c("i", { class: _vm.$config.icons.nav.preferences }),
          _vm._v(" page. ")
        ]),
        _vm._v(" to fix your subscription. ")
      ],
      1
    ),
    _c("p", { staticClass: "question" }, [
      _vm._v("Q. One of the shared devices in my list is coming up as red?")
    ]),
    _c("p", [
      _vm._v(
        " A. The person who has shared the device with you no longer has a valid subscription. If the owner of a device does not have a valid subscription, you will not be able to access the device's information. "
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("a", { attrs: { href: "#heading-1" } }, [
          _vm._v("1. Basic Tracking")
        ])
      ]),
      _c("li", [
        _c("a", { attrs: { href: "#heading-2" } }, [_vm._v("2. Fleet Screen")])
      ]),
      _c("li", [
        _c("a", { attrs: { href: "#heading-3" } }, [_vm._v("3. Subscriptions")])
      ]),
      _c("li", [
        _c("a", { attrs: { href: "#heading-4" } }, [
          _vm._v("4. Sharing Devices")
        ])
      ]),
      _c("li", [
        _c("a", { attrs: { href: "#heading-5" } }, [
          _vm._v("5. Notification Rules")
        ])
      ]),
      _c("li", [_c("a", { attrs: { href: "#heading-6" } }, [_vm._v("6. FAQ")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("a", { attrs: { id: "heading-1" } }),
      _vm._v("1. Basic Tracking")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("a", { attrs: { id: "heading-2" } }),
      _vm._v("2. Fleet Screen")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("a", { attrs: { id: "heading-3" } }),
      _vm._v("3. Subscriptions")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("a", { attrs: { id: "heading-4" } }),
      _vm._v("4. Sharing Devices")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("a", { attrs: { id: "heading-5" } }),
      _vm._v("5. Notification Rules")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " Currently there are four different notification types available. These determine what method will be used to send a notification for an event. "
      ),
      _c("ol", [
        _c("li", [
          _c("strong", [_vm._v("Email")]),
          _vm._v(
            " - Email Notifications are sent via Email to the address registered with your account "
          )
        ]),
        _c("li", [
          _c("strong", [_vm._v("Push")]),
          _vm._v(
            " - Push Notifications are sent via iOS/Android Push to any device that you have installed the Pro-Tekt mobile app on. "
          )
        ]),
        _c("li", [
          _c("strong", [_vm._v("Web")]),
          _vm._v(
            " - Web Notifications are only shown via the Pro-Tekt Website. "
          )
        ]),
        _c("li", [
          _c("strong", [_vm._v("None")]),
          _vm._v(
            " - No Notifications will be sent when this rule is being followed. "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", [
      _c("li", [
        _c("strong", [_vm._v("Vehicle Status")]),
        _vm._v(
          " - Events related to the physical state of the vehicle and the Pro-Tekt Tracking device installed in it. e.g. Low Battery Voltage, Tamper Alarm, etc "
        )
      ]),
      _c("li", [
        _c("strong", [_vm._v("Location Events")]),
        _vm._v(
          " - Events related to the location of the vehicle e.g. Geolimit IN and OUT "
        )
      ]),
      _c("li", [
        _c("strong", [_vm._v("Driving Events")]),
        _vm._v(
          " - Events related to the way the vehicle is being driven e.g. Overspeed Alarms, Harsh Braking or Acceleration, etc. "
        )
      ]),
      _c("li", [
        _c("strong", [_vm._v("User Events")]),
        _vm._v(
          " - Events added via the Fleet Screen by users. e.g. Vehicle Service events, Fuel stops. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" The "),
      _c("strong", [_vm._v("Default Rules")]),
      _vm._v(
        " category is used to create rules which will apply to all vehicles that a user has access to. Note that rules for specific vehicles, will always take precedence over rules added to the default category. "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " In the upper-right corner of the Notification Rules tab there is a button labelled "
      ),
      _c("strong", [_vm._v("Reset All")]),
      _vm._v(
        ", which will REMOVE all custom notification rules and reset your notifications to default. Please note that we cannot reverse this action and if you accidentally remove custom rules you have created, you will need to create them again yourself. "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("a", { attrs: { id: "heading-6" } }),
      _vm._v("6. Frequently Asked Questions")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }