var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-user-subscription" },
    [
      _c(
        "b-alert",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.invoiceOverDueLink,
              expression: "invoiceOverDueLink"
            }
          ],
          attrs: { variant: "danger", show: "" }
        },
        [
          _vm._v(
            " Your subscription is overdue, your site access will be limited until your overdue invoice is paid. Click "
          ),
          _c(
            "a",
            { attrs: { href: _vm.invoiceOverDueLink, target: "_blank" } },
            [_vm._v("HERE")]
          ),
          _vm._v(" to see the invoice. ")
        ]
      ),
      _c("LoadingBox", { attrs: { loading: _vm.loading } }),
      !_vm.loading && _vm.subscription
        ? _c(
            "div",
            { staticClass: "field-container" },
            [
              _c("SubscriptionSummary", {
                attrs: {
                  subscription: _vm.subscription,
                  expandable_products: "",
                  show_change_buttons: ""
                },
                on: {
                  "click-primary": _vm.clickChangePrimary,
                  "click-addons": _vm.clickChangeAddons
                }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.loading && !_vm.subscription
        ? _c(
            "div",
            { staticClass: "field-container" },
            [
              _c(
                "b-alert",
                { attrs: { variant: "danger", show: "" } },
                [
                  _vm._v(
                    "You do not have a valid subscription associated with your account. Click "
                  ),
                  _c("router-link", { attrs: { to: "subscribe" } }, [
                    _vm._v("HERE")
                  ]),
                  _vm._v(" to create a subscription and start using Pro-Tekt.")
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "divider" }),
      !_vm.loading
        ? _c("div", { staticClass: "field-container" }, [
            _c("div", { staticClass: "col col-product" }, [
              _c(
                "label",
                { staticClass: "field-label", attrs: { for: "product_name" } },
                [_vm._v("Billing Portal")]
              ),
              _c("p", [
                _vm._v(
                  "Change payment details, view invoices and subscription details."
                )
              ]),
              _c(
                "button",
                {
                  staticClass: "button",
                  on: { click: _vm.clickBillingPortal }
                },
                [_vm._v("Billing Portal")]
              )
            ])
          ])
        : _vm._e(),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-change-subscription",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Change Subscription Type"
          }
        },
        [
          _vm.subscription && !_vm.saveInProgress
            ? _c(
                "div",
                { staticClass: "overflow-auto" },
                [
                  _c("product-selector", {
                    attrs: {
                      "product-id": _vm.primaryProduct.product_id,
                      product_types: ["primary"],
                      vertical: ""
                    },
                    on: { select: _vm.selectPrimaryProduct }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "flex-row justify-content-end" }, [
            _c(
              "button",
              {
                staticClass: "button",
                on: {
                  click: function($event) {
                    _vm.newProduct = null
                    _vm.$bvModal.hide("modal-change-subscription")
                  }
                }
              },
              [_vm._v("Cancel")]
            ),
            _c(
              "button",
              {
                staticClass: "button",
                attrs: { disabled: !_vm.newProduct },
                on: {
                  click: function($event) {
                    return _vm.saveChangeSubscription(
                      "modal-change-subscription"
                    )
                  }
                }
              },
              [_vm._v("Change Subscription")]
            )
          ])
        ]
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-change-addons",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Change Subscription Type"
          }
        },
        [
          _vm.subscription
            ? _c(
                "div",
                { staticClass: "overflow-auto" },
                [
                  _c("product-selector", {
                    attrs: {
                      select_mode: "multi",
                      product_types: ["addon"],
                      product: _vm.addonProducts
                    },
                    on: { select: _vm.selectAddons }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "flex-row justify-content-end" }, [
            _c(
              "button",
              {
                staticClass: "button",
                on: {
                  click: function($event) {
                    _vm.newAddons = null
                    _vm.$bvModal.hide("modal-change-addons")
                  }
                }
              },
              [_vm._v("Cancel")]
            ),
            _c(
              "button",
              {
                staticClass: "button",
                attrs: { disabled: !_vm.newAddons },
                on: {
                  click: function($event) {
                    return _vm.saveChangeSubscription("modal-change-addons")
                  }
                }
              },
              [_vm._v("Change Subscription")]
            )
          ])
        ]
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-subscription-update",
            centered: "",
            size: "lg",
            "hide-footer": "",
            title: "Saving Subscription Changes"
          }
        },
        [
          _c(
            "div",
            { staticClass: "update-indicator w-100" },
            [
              _c("h2", [
                _vm._v(" " + _vm._s(_vm.progressMessage) + " "),
                _vm.progressValue < 100
                  ? _c("i", { staticClass: "ml-1 fa fa-cog rotate-icon" })
                  : _c("i", { staticClass: "fa fa-check" })
              ]),
              _c(
                "b-progress",
                { staticClass: "w-100", attrs: { max: "100" } },
                [
                  _c("b-progress-bar", {
                    attrs: { value: _vm.progressValue, animated: "" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "flex-row justify-content-end" }, [
            _c(
              "button",
              {
                staticClass: "button",
                on: {
                  click: function($event) {
                    return _vm.$bvModal.hide("modal-subscription-update")
                  }
                }
              },
              [_vm._v("Close")]
            ),
            _c(
              "button",
              {
                staticClass: "button",
                attrs: { disabled: !_vm.newProduct },
                on: { click: _vm.saveChangeSubscription }
              },
              [_vm._v("Change Subscription")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }