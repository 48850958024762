<template>
  <div class="edit-user-subscription" >
    <b-alert variant="danger" show v-show="invoiceOverDueLink">
      Your subscription is overdue, your site access will be limited until your overdue invoice is paid.
      Click <a :href="invoiceOverDueLink" target="_blank">HERE</a> to see the invoice. </b-alert>
    <LoadingBox :loading="loading"></LoadingBox>
<!--    Controls if user has a subscription-->
    <div class="field-container" v-if="!loading && subscription">
      <SubscriptionSummary :subscription="subscription" expandable_products show_change_buttons
                           @click-primary="clickChangePrimary"
                           @click-addons="clickChangeAddons"

      ></SubscriptionSummary>
<!--      <div class="col col-product">-->
<!--        <label for="product_name" class="field-label">Subscription Type</label>-->
<!--        <b-input-group class="combined-input">-->
<!--          <input type="text" v-model="primaryName" class="text-field w-input sub-input"-->
<!--                 maxlength="256" placeholder="Subscription Type" id="product_name" disabled>-->
<!--          <b-button class="button" @click="$bvModal.show('modal-change-subscription')">Change</b-button>-->
<!--        </b-input-group>-->
<!--      </div>-->
<!--      <div class="col col-volume">-->
<!--        <label for="status" class="field-label">Status</label>-->
<!--        <b-input-group class="combined-input">-->
<!--          <input type="text" v-model="this.statusText[this.subscription.status].title"-->
<!--                 class="text-field w-input w-80 text-center"-->
<!--                 maxlength="256" placeholder="Status" id="status" disabled>-->
<!--          <i class="fa fa-info-circle" v-b-tooltip.hover :title="this.statusText[this.subscription.status].description"></i>-->
<!--        </b-input-group>-->
<!--      </div>-->
<!--      <div class="col col-volume" :class="{ 'form-error': $v.userData.name.$error }">-->
<!--        <label for="device_volume" class="field-label"># Devices</label>-->
<!--        <input type="text" v-model="lastVolume" class="text-field w-input w-25 text-center"-->
<!--               maxlength="256" placeholder="Volume" id="device_volume" disabled>-->
<!--        <div class="input-error" v-if="!$v.userData.name.required">Field is Required</div>-->
<!--      </div>-->
    </div>
    <!--    Controls if user has a subscription-->
    <div class="field-container" v-if="!loading && !subscription">
      <b-alert variant="danger" show>You do not have a valid subscription associated with your account.
        Click <router-link to="subscribe">HERE</router-link> to create a subscription and start using Pro-Tekt.</b-alert>
    </div>
    <div class="divider"></div>
    <div class="field-container" v-if="!loading">
      <div class="col col-product">
        <label for="product_name" class="field-label">Billing Portal</label>
        <p>Change payment details, view invoices and subscription details.</p>
        <button class="button" @click="clickBillingPortal">Billing Portal</button>
      </div>
    </div>
<!--    <div class="footer mt-2">-->
<!--      <b-button class="button" @click="clickClose()">Close</b-button>-->
<!--      <b-button class="button" @click="saveChanges()">Save Changes</b-button>-->
<!--    </div>-->
    <!--    Change Subscription Tier Modal-->
    <b-modal id="modal-change-subscription" centered class="modal-content" size="xl" hide-footer title="Change Subscription Type">
      <div class="overflow-auto" v-if="subscription && !saveInProgress">
        <product-selector :product-id="primaryProduct.product_id" @select="selectPrimaryProduct" :product_types="['primary']" vertical></product-selector>
      </div>
      <div class="flex-row justify-content-end">
        <button class="button" @click="newProduct = null;$bvModal.hide('modal-change-subscription')">Cancel</button>
        <button class="button" @click="saveChangeSubscription('modal-change-subscription')" :disabled="!newProduct">Change Subscription</button>
      </div>
    </b-modal>

    <b-modal id="modal-change-addons" centered class="modal-content" size="xl" hide-footer title="Change Subscription Type">
      <div class="overflow-auto" v-if="subscription">
        <product-selector select_mode="multi" :product_types="['addon']" :product="addonProducts" @select="selectAddons"
        ></product-selector>
      </div>
      <div class="flex-row justify-content-end">
        <button class="button" @click="newAddons = null;$bvModal.hide('modal-change-addons')">Cancel</button>
        <button class="button" @click="saveChangeSubscription('modal-change-addons')" :disabled="!newAddons">Change Subscription</button>
      </div>
    </b-modal>

    <b-modal id="modal-subscription-update" centered class="modal-content" size="lg" hide-footer title="Saving Subscription Changes">
      <div class="update-indicator w-100">
        <h2>
          {{progressMessage}}
          <i class="ml-1 fa fa-cog rotate-icon" v-if="progressValue < 100"></i>
          <i class="fa fa-check" v-else></i>
        </h2>
        <b-progress  max="100" class="w-100">
          <b-progress-bar :value="progressValue" animated>
          </b-progress-bar>
        </b-progress>
      </div>
      <div class="flex-row justify-content-end">
        <button class="button" @click="$bvModal.hide('modal-subscription-update')">Close</button>
        <button class="button" @click="saveChangeSubscription" :disabled="!newProduct">Change Subscription</button>
      </div>
    </b-modal>

  </div>
</template>

<script>
import * as DataProvider from './helpers/DataProvider'
import * as ErrorHelper from './helpers/ErrorHelper'
import * as AlertHelper from './helpers/AlertHelper'
import LoadingBox from './helpers/LoadingBox'
import { minLength, required } from 'vuelidate/lib/validators'
import ProductSelector from '@/components/ProductSelector'
import SubscriptionSummary from '@/components/SubscriptionSummary.vue'
// import * as Auth from '../router/auth'

export default {
  name: 'edit-user-subscription',
  components: { SubscriptionSummary, ProductSelector, LoadingBox },
  props: {
    isModal: Boolean,
    modal: String,
    user: Object
  },
  data: function () {
    return {
      loading: true,
      showButtons: true,
      subscription: null,
      productId: null,
      newProduct: null,
      newAddons: null,
      statusText: {
        'load': { title: 'Loading...', description: 'Still Loading' },
        'active': { title: 'Active', description: 'Your subscription is up to date.' },
        'canceled': { title: 'Canceled', description: 'Your subscription has been cancelled.' },
        'unpaid': { title: 'Unpaid', description: 'You have an unpaid invoice!' },
        'past_due': { title: 'Overdue', description: 'You have an unpaid invoice that is past the due date!' }
      },
      invoiceOverDueLink: null,
      saveInProgress: false,
      progressMessage: '',
      progressValue: 0,
    }
  },
  validations: {
    userData: {
      name: {
        required,
        minLength: minLength(15)
      }
    }
  },
  async created () {
    this.loadSubscriptionData()
    // Hide Controls so the parent modal can display them
    if (this.isModal) {
      this.showButtons = false
    }
    this.loading = false
  },
  methods: {
    loadSubscriptionData: async function () {
      // Note: We're using the /subscription endpoint which should provide fresh data
      let response = await DataProvider.getSubscription()
      if (!response.error) {
        this.updateSubscriptionData(response.data.subscriptions)
      } else {
        ErrorHelper.displayGeneralErrorToast('Failed to load Subscription Data!', 'Something went wrong!')
      }
      this.loading = false
    },
    updateSubscriptionData: function (subData) {
      let activeSubs = subData.filter(x => !this.$config.auth.canceledSubscriptionStates.includes(x.status))
      if (activeSubs.length > 0) {
        this.subscription = activeSubs[0]
        // this.productId = activeSubs[0].product_id
        if (this.$config.auth.actionRequiredSubscriptionStates.includes(this.subscription.status)) {
          if (this.subscription.status === 'past_due') {
            this.invoiceOverDueLink = this.subscription.last_invoice_url
          }
        }
      } else {
        // Setting the subscription to null will cause an alert to appear prompting the user to subscribe.
        this.subscription = null
      }
      if (activeSubs.length > 1) {
        ErrorHelper.displayGeneralWarningToast('You have more than one subscription! Most people only need one. ' +
          'You can cancel any extra subscriptions in the Billing Portal.', 'Multiple Subscriptions!')
      }
      console.log('SUB: ', this.subscription)
    },
    clickBillingPortal: async function () {
      let resp = await DataProvider.getBillingPortalSession()
      console.log(resp)
      if (resp.success) {
        console.log('Portal link: ', resp.data.portal_url)
        window.open(resp.data.portal_url)
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    // User wants to change to a new subscription type...
    async saveChangeSubscription (modalId) {
      this.saveInProgress = true
      this.progressMessage = 'Collecting Product Ids...'
      this.progressValue = 25
      await this.$bvModal.hide(modalId)
      await this.$bvModal.show('modal-subscription-update')
      setTimeout(async () => {
        this.progressMessage = 'Updating Subscription Products...'
        this.progressValue = 50
        let productIds
        if (this.newProduct) {
          productIds = [this.newProduct.product_id, ...this.addonProducts.map(p => p.product_id)]
        } else if (this.newAddons) {
          productIds = [this.primaryProduct.product_id, ...this.newAddons.map(p => p.product_id)]
        } else {
          console.error('No Subscription changes to save!')
        }
        await this.updateSubscriptionProducts(productIds)
        this.$nextTick(() => {
          this.progressMessage = 'Subscription Update Complete!'
          this.progressValue = 100
          setTimeout(() => {
            this.$bvModal.hide('modal-subscription-update')
            this.saveInProgress = false
            this.progressMessage = ''
            this.progressValue = 0
          }, 500)
        })
      }, 200)
    },
    async updateSubscriptionProducts(newProuctIds) {
      let res = await DataProvider.updateSubscription(newProuctIds)
      console.log(res)
      if (res.success) {
        AlertHelper.successToast('Subscription Type updated!', 'Your subscription type has been updated')
        this.updateSubscriptionData([res.data.subscription])

      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    selectPrimaryProduct (newProduct) {
      if (newProduct.product_id !== this.primaryProduct.product_id) {
        this.newProduct = newProduct
      } else {
        this.newProduct = null
      }
    },
    selectAddons (newProducts) {
      let newProductIds = newProducts.map(p => p.product_id).sort()
      if (newProductIds.toString() !== this.addonProuctIds.toString()) {
        this.newAddons = newProducts
      } else {
        this.newAddons = null
      }
    },
    clickChangePrimary() {
      this.$bvModal.show('modal-change-subscription')
    },
    clickChangeAddons() {
      this.$bvModal.show('modal-change-addons')
    }
  },
  computed: {
    primaryProduct () {
      if (this.subscription) {
        console.log(this.subscription)
        return this.subscription.products.find(p => p.product_type === 'primary')
      } else {
        return null
      }
    },
    addonProducts () {
      if (this.subscription) {
        console.log(this.subscription)
        return this.subscription.products.filter(p => p.product_type === 'addon')
      } else {
        return null
      }
    },
    addonProuctIds () {
      return this.addonProducts.map(p => p.product_id).sort()
    }

  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/variables';
  @import '@/custom';

  .edit-user-subscription {
    color: $text-color-invert;
    padding: 20px;
  }

  .button {
    margin: 0px 5px;
  }

  .field-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: stretch;
  }

  .field-label{
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .divider {
    width: 100%;
    border-bottom: 1px solid $theme-color-primary-1;
    margin: 20px 0px;
  }

  .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  input {
    margin-bottom: 0;
  }

  .w-80 {
    width: 80%;
  }

  .col-product {
    justify-items: center;
    flex-grow: 3;
    min-width: fit-content;
    margin-top: 1rem;
  }

  .col-volume {
    justify-items: center;
    flex-grow: 1;
    min-width: fit-content;
    margin-top: 1rem;
  }

  .sub-input {
    //width: auto;
    flex-grow: 3;
  }

  .combined-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }

  .footer{
    width: 100%;
    bottom: 2%;
    display: flex;
    justify-content: flex-end;
    margin: 0 1% 0 1%;
  }

  .update-indicator {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    font-size: 2em;
    margin-bottom: 2em;
  }

  @media screen and (max-width: 600px) {
    .combined-input {
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 420px) {
    .edit-user-subscription {
      padding: 2px;
    }
    .modal-content{
      button {
        margin-top: 5px;
        font-size: 0.7em;
        padding: 2px 10px;
      }

    }
  }
</style>
