
// Custom Validators for Vuelidate Forms.

export function isJSON (data) {
  console.log(data)
  if (!data) {
    return true
  } else {
    try {
      JSON.parse(data)
    } catch (e) {
      return false
    }
    return true
  }
}
