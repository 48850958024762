<template>
  <div class="edit-user-preferences flex-column" >
    <LoadingBox :loading="loading"></LoadingBox>
    <h4 class="mt-0 mb-0">User Profile</h4>
    <div class="green-divider"></div>
      <div class="flex-row flex-grow-1 justify-content-between align-content-start" v-if="!loading">
        <div class="flex-column flex-grow-1 align-content-end" :class="{ 'form-error': $v.userData.name.$error }">
          <label for="user_name" class="field-label">Full Name</label>
          <input type="text" v-model="$v.userData.name.$model" class="w-100"
                 maxlength="256" placeholder="Full Name" id="user_name">
          <div class="input-error" v-if="!$v.userData.name.required">Field is Required</div>
        </div>
        <div class="flex-column flex-grow-1 ml-2" :class="{ 'form-error': $v.userData.email.$error }">
          <label for="email_address" class="field-label">Email</label>
          <input type="text" v-model="$v.userData.email.$model" class="w-100"
                 maxlength="256" placeholder="Email Address" id="email_address">
          <div class="input-error" v-if="!$v.userData.email.required">Field is Required</div>
          <div class="input-error" v-if="!$v.userData.email.email">Must be a valid email address</div>
        </div>
        <div class="flex-column flex-grow-1 ml-2" :class="{ 'form-error': $v.userData.mobile.$error }">
          <label for="Registration" class="field-label">Mobile Number</label>
          <input type="text" v-model="$v.userData.mobile.$model" class="w-100"
                 maxlength="256" name="Registration" data-name="Registration" placeholder="0000 000 000" id="Registration">
          <div class="input-error" v-if="!$v.userData.mobile.required">Field is Required</div>
          <div class="input-error" v-if="!$v.userData.mobile.minLength || !$v.userData.email.maxLength">Must be 10 characters long</div>
        </div>
      </div>
    <h4 class="mt-4 mb-0">Interface Settings</h4>
    <div class="green-divider"></div>

    <div class="flex-row flex-grow-1 justify-content-between align-content-start" v-if="!loading">
      <div class="flex-column flex-grow-1 ml-2" :class="{ 'form-error': $v.userData.mobile.$error }">
        <b-checkbox switch :checked="showParkedDetails" @change="changeSetting(!showParkedDetails, 'showParkedLocations')">Show Parked Details</b-checkbox>
        <div class="input-error" v-if="!$v.userData.mobile.required">Field is Required</div>
        <div class="input-error" v-if="!$v.userData.mobile.minLength || !$v.userData.email.maxLength">Must be 10 characters long</div>
      </div>
    </div>
    <div class="flex-row mt-2 justify-content-end">
      <button class="button" @click="$bvModal.show('modal-change-password')">Change Password</button>
      <button class="button" @click="clickClose()">Close</button>
      <button class="button" @click="saveChanges()">Save Changes</button>
    </div>

    <!--    Forgotten Password Modal Template-->
    <b-modal id="modal-change-password" centered class="modal-content" size="lg" hide-footer title="Change Password">
      <b-form v-on:submit.prevent="clickChangePassword">
        <div class="field-container">
          <div class="property-item">
            <label for="password" class="device-label">Enter a New Password:</label>
            <input type="password" v-model="password" class="text-field w-input"
                   maxlength="256" placeholder="New Password" id="password">
          </div>
          <div class="property-item">
            <label for="confirm-password" class="device-label">Retype New Password:</label>
            <input type="password" v-model="passwordConfirm" class="text-field w-input"
                   maxlength="256" placeholder="Confirm Password" id="confirm-password">
          </div>
        </div>
        <div class="footer">
          <b-button class="button" @click="$bvModal.hide('modal-change-password')">Cancel</b-button>
<!--          <b-button class="button" @click="clickChangePassword()">Change Password</b-button>-->
          <b-button type="submit" class="button">Change Password</b-button>
        </div>
      </b-form>

    </b-modal>
  </div>
</template>

<script>
import * as DataProvider from './helpers/DataProvider'
import * as ErrorHelper from './helpers/ErrorHelper'
import LoadingBox from './helpers/LoadingBox'
import { minLength, required, maxLength, email } from 'vuelidate/lib/validators'

export default {
  name: 'edit-user-preferences',
  components: { LoadingBox },
  props: {
    isModal: Boolean,
    modal: String,
    user: Object
  },
  data: function () {
    return {
      loading: true,
      showButtons: true,
      displayDevice: null,
      isNewUser: true,
      selectedDevices: null,
      userData: {
        name: '',
        email: '',
        mobile: ''
      },
      password: '',
      passwordConfirm: '',
      showParkedDetails: false
    }
  },
  validations: {
    userData: {
      name: {
        required,
        minLength: minLength(1)
      },
      email: {
        required,
        email
      },
      mobile: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10)
      }
    }
  },
  async created () {
    await this.loadUserData()
    await this.loadLocalSettings()
    // Hide Controls so the parent modal can display them
    if (this.isModal) {
      this.showButtons = false
    }
  },
  methods: {
    async changeSetting (value, propName) {
      await this.$auth.setUserSetting(propName, value)
    },
    async loadLocalSettings () {
      this.showParkedDetails = await this.$auth.getUserSetting('showParkedLocations', false)
    },
    loadUserData: async function () {
      let response = await DataProvider.getUserProfile()
      console.log('User Profile: ', response)
      if (response.success) {
        this.userData = response.data
      } else {
        ErrorHelper.displayDataErrorToast(response)
      }
      this.loading = false
    },
    saveChanges: async function () {
      if (this.$v.$anyError) {
        ErrorHelper.displayGeneralErrorToast('Some Fields Contain Invalid Data. Please fix them.', 'Invalid Fields')
        return
      }

      let res = await DataProvider.setUserProfile(this.userData.name, this.userData.email, this.userData.mobile)
      if (res.success) {
        this.$v.$reset()
        this.$bvToast.toast('The changes you made have been saved!',
          {
            title: 'Changes Saved',
            variant: 'success',
            toaster: 'b-toaster-top-center'
          })
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    clickChangePassword: async function (e) {
      if (this.password === '') {
        ErrorHelper.displayGeneralErrorToast('You must enter a new password.', 'Password Blank')
        return
      }
      if (this.password !== this.passwordConfirm) {
        ErrorHelper.displayGeneralErrorToast('Your Password and Confirmed passwords do not match! Please enter them again.', 'Password Mismatch')
        return
      }
      let response = await DataProvider.changePassword(this.password, this.passwordConfirm)
      if (response.success) {
        this.$bvToast.toast('Your password has been changed.',
          {
            title: 'Changes Saved',
            variant: 'success',
            toaster: 'b-toaster-top-center'
          })
        this.$bvModal.hide('modal-change-password')
      } else {
        ErrorHelper.displayDataErrorToast(response)
      }
    },
    clickClose: async function () {
      if (this.$v.$anyDirty) {
        let confirm = await this.$bvModal.msgBoxConfirm('You have unsaved changes. Are you sure you want to leave?', {
          title: 'Confirm Abandon Changes',
          okVariant: 'warning',
          centered: true
        })
        if (!confirm) {
          return
        }
      }

      // Check if we've been passed a modal to close.
      if (this.modal) {
        this.$bvModal.hide(this.modal)
      } else {
        this.$router.go(-1)
      }
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../variables';

  .edit-user-preferences {
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: stretch;
    align-items: stretch;
    justify-items: stretch;
    flex-grow: 1;
    color: $text-color-invert;
  }

  .button {
    margin: 0px 5px;
  }

  .field-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .field-col {
    min-width: fit-content;
  }

  .field-label{
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .footer{
    width: 100%;
    bottom: 2%;
    display: flex;
    justify-content: flex-end;
    margin: 0 1% 0 1%;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 600px) {
    .footer {
      justify-content: space-around;
      margin: 0;
    }
    .button {
      margin-top: 5%;
    }
  }

</style>
