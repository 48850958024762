var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-user-report-preferences" },
    [
      _c("LoadingBox", { attrs: { loading: _vm.loading } }),
      !_vm.loading
        ? _c("div", { staticClass: "field-container" }, [
            _vm.userData["settings"]
              ? _c(
                  "div",
                  [
                    _c("label", { staticClass: "green-label" }, [
                      _vm._v("Monthly Report")
                    ]),
                    _c(
                      "b-form-checkbox",
                      {
                        model: {
                          value:
                            _vm.userData["settings"]["email_monthly_report"],
                          callback: function($$v) {
                            _vm.$set(
                              _vm.userData["settings"],
                              "email_monthly_report",
                              $$v
                            )
                          },
                          expression:
                            "userData['settings']['email_monthly_report']"
                        }
                      },
                      [_vm._v("Send Monthly Email Summary Report?")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "footer mt-2" },
        [
          _c(
            "b-button",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.clickClose()
                }
              }
            },
            [_vm._v("Close")]
          ),
          _c(
            "b-button",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.saveChanges()
                }
              }
            },
            [_vm._v("Save Changes")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }