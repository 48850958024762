<template>
  <div class="edit-user-report-preferences" >
    <LoadingBox :loading="loading"></LoadingBox>
      <div class="field-container" v-if="!loading">

        <div v-if="userData['settings']">
          <label class="green-label">Monthly Report</label>
          <b-form-checkbox v-model="userData['settings']['email_monthly_report']">Send Monthly Email Summary Report?</b-form-checkbox>
        </div>
      </div>

    <div class="footer mt-2">
      <b-button class="button" @click="clickClose()">Close</b-button>
      <b-button class="button" @click="saveChanges()">Save Changes</b-button>
    </div>
  </div>
</template>

<script>
import * as DataProvider from './helpers/DataProvider'
import * as ErrorHelper from './helpers/ErrorHelper'
import LoadingBox from './helpers/LoadingBox'

export default {
  name: 'edit-user-report-preferences',
  components: { LoadingBox },
  props: {
    isModal: Boolean,
    modal: String,
    user: Object
  },
  data: function () {
    return {
      loading: true,
      showButtons: true,
      userData: {
        settings: null
      }
    }
  },
  validations: {
  },
  async created () {
    this.loadUserData()
    // Hide Controls so the parent modal can display them
    if (this.isModal) {
      this.showButtons = false
    }
    this.loading = false
  },
  methods: {
    loadUserData: async function () {
      let response = await DataProvider.getUserProfile()
      console.log('User Profile: ', response)
      if (response.success) {
        this.userData = response.data
      } else {
        ErrorHelper.displayDataErrorToast(response)
      }
      this.loading = false
    },
    saveChanges: async function () {
      if (this.$v.$anyError) {
        ErrorHelper.displayGeneralErrorToast('Some Fields Contain Invalid Data. Please fix them.', 'Invalid Fields')
        return
      }

      let res = await DataProvider.setUserSettings(this.userData.settings)
      if (res.success) {
        this.$v.$reset()
        this.$bvToast.toast('The changes you made have been saved!',
          {
            title: 'Changes Saved',
            variant: 'success',
            toaster: 'b-toaster-top-center'
          })
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    clickChangePassword: async function (e) {
      if (this.password === '') {
        ErrorHelper.displayGeneralErrorToast('You must enter a new password.', 'Password Blank')
        return
      }
      if (this.password !== this.passwordConfirm) {
        ErrorHelper.displayGeneralErrorToast('Your Password and Confirmed passwords do not match! Please enter them again.', 'Password Mismatch')
        return
      }
      let response = await DataProvider.changePassword(this.password, this.passwordConfirm)
      if (response.success) {
        this.$bvToast.toast('Your password has been changed.',
          {
            title: 'Changes Saved',
            variant: 'success',
            toaster: 'b-toaster-top-center'
          })
        this.$bvModal.hide('modal-change-password')
      } else {
        ErrorHelper.displayDataErrorToast(response)
      }
    },
    clickClose: async function () {
      if (this.$v.$anyDirty) {
        let confirm = await this.$bvModal.msgBoxConfirm('You have unsaved changes. Are you sure you want to leave?', {
          title: 'Confirm Abandon Changes',
          okVariant: 'warning',
          centered: true
        })
        if (!confirm) {
          return
        }
      }

      // Check if we've been passed a modal to close.
      if (this.modal) {
        this.$bvModal.hide(this.modal)
      } else {
        this.$router.go(-1)
      }
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../variables';

  .edit-user-preferences {
    color: $text-color-invert;
    padding: 20px;
  }

  .button {
    margin: 0px 5px;
  }

  .field-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .field-col {
    min-width: fit-content;
  }

  .field-label{
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .footer{
    width: 100%;
    bottom: 2%;
    display: flex;
    justify-content: flex-end;
    margin: 0 1% 0 1%;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 600px) {
    .footer {
      justify-content: space-around;
      margin: 0;
    }
    .button {
      margin-top: 5%;
    }
  }

</style>
