<template>
  <div class="preferences">
    <b-card class="pref-card">
      <b-card-header>User Preferences</b-card-header>
      <b-card-body>
        <b-tabs v-model="activeTab">
          <b-tab title="Preferences">
            <div class="base-card">
              <edit-user-preferences></edit-user-preferences>
            </div>
          </b-tab>
          <b-tab title="Notification Rules">
            <div class="base-card">

              <edit-user-notifications></edit-user-notifications>
            </div>
          </b-tab>
          <b-tab title="Subscription">
            <div class="base-card">
              <edit-user-subscription></edit-user-subscription>
            </div>
          </b-tab>
          <b-tab title="App Download">
            <div class="base-card app-card">
              <div>
                You can use the below download links to access the Pro-Tekt GPS
                Tracking App for both Android and Apple devices.
              </div>
              <div class="app-link">
                <!--                  style="border-radius: 13px; width: 250px; height: 83px; overflow: hidden"-->
                <a
                  href="https://play.google.com/store/apps/details?id=au.com.pro_tekt.ios&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                >
                  <img
                    alt="Get it on Google Play"
                    src="@/assets/google-play-badge.png"
                    style="width: 100%"
                  />
                </a>
              </div>
              <div class="app-link" style="padding: 15px">
                <a
                  href="https://apps.apple.com/us/app/pro-tekt/id1230764414?itsct=apps_box&amp;itscg=30200"
                >
                  <img
                    src="@/assets/itunes-black.svg"
                    alt="Download on the App Store"
                    style="width: 100%"
                  />
                </a>
              </div>
            </div>
          </b-tab>
          <b-tab title="Reports" v-if="$auth.hasRole('fleet_user')">
            <div class="base-card">
              <edit-user-report-preferences></edit-user-report-preferences>
            </div>
          </b-tab>
          <b-tab title="API Access">
            <div class="base-card">
              <user-api-access></user-api-access>
            </div>
          </b-tab>
          <b-tab title="Help">
            <div class="base-card">
              <user-help></user-help>
            </div>
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import EditUserPreferences from '../components/EditUserPreferences'
import EditUserReportPreferences from '../components/EditUserReportPreferences'
import UserApiAccess from '../components/UserApiAccess'
import EditUserNotifications from '@/components/notifications/EditUserNotifications'
import EditUserSubscription from '../components/EditUserSubscription'
import UserHelp from '@/components/UserHelp'

export default {
  name: 'preferences',
  components: {
    UserHelp,
    EditUserPreferences,
    EditUserReportPreferences,
    EditUserSubscription,
    EditUserNotifications,
    UserApiAccess,
  },
  data: function () {
    return {
      tab: null,
      activeTab: 0,
    }
  },
  created() {
    if (this.$route.query.hasOwnProperty('tab')) {
      this.tab = this.$route.query.tab
      console.log('Tab Selection: ', this.tab)
      if (this.tab === 'subscription') {
        this.activeTab = 2
      } else if (this.tab === 'app-download') {
        this.activeTab = 3
      } else if (this.tab === 'reports') {
        this.activeTab = 4
      } else if (this.tab === 'user-api') {
        this.activeTab = 5
      } else if (this.tab === 'help') {
        this.activeTab = 6
      }
    }
  },
  methods: {},
}
</script>

<style scoped lang="scss">
@import '@/variables';

.preferences {
  width: 100vw;
  height: 100vh;
  background: $default-sidebar-background;
  color: $text-color-invert;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.base-card {
  display: flex;
  flex-flow: column wrap;
  padding: 1%;
  min-height: 30vh;
  max-height: 80vh;
  overflow-y: auto;
}

.pref-card {
  width: 80%;
}

.app-card {
  width: 100%;
}

.app-link {
  width: 250px;
}

.help-card {
  text-align: start;
}

strong {
  color: $theme-color-primary-3;
}

@media screen and (max-width: 600px) {
  .pref-card {
    width: 95%;
  }

  .base-card {
    max-height: 95vh;
  }
}
</style>
