<template>
  <div class="user-api-access">
    <LoadingBox :loading="loading"></LoadingBox>
    <div class="" v-if="!loading">
<!--      <p>-->
<!--        API Access allows you to integrate Pro-tekt with other computer settings.-->
<!--        Most users will not need to do this - typically only software developers or data analysts will need to do create clients and webhooks here.-->
<!--        If you don't know what these settings mean, you can leave them all as they are - it won't affect your usage of our system.-->
<!--      </p>-->
      <div class="">
        <label for="product_name" class="field-label">OAuth2 API Access</label>
<!--        <p>-->
<!--          Modern systems typically use an OAuth2 flow. In this case, both a-->
<!--          Client ID and an Access Token are needed. To use this API, you use-->
<!--          your client ID and client secret to generate an access token, and use-->
<!--          that token to make further calls.-->
<!--        </p>-->
<!--        <p>-->
<!--          At this stage, API access is only supported for back-end processes,-->
<!--          not for user-facing front ends.-->
<!--        </p>-->
        <b-table striped hover :items="oauthTokens" :fields="oauthFields" v-if="oauthTokens.length > 0">
          <template v-slot:cell(delete)="row">
            <div class="row">
              <i
                class="icon-button row-icon-button-danger"
                :class="$config.icons.general.remove"
                v-b-tooltip.hover
                title="Remove/Reset"
                v-if="!row.item.disable_delete"
                @click="revokeClient(row.item)"
              >
                Revoke Key</i
              >
            </div>
          </template>
        </b-table>
        <div v-if="oauthTokens.length === 0">You do not have any clients set up yet. Set up one using the form below.</div>
        <div class="col field-col">
          <label for="auth_token" class="field-label">Add New Client</label>
          <input
            type="text"
            v-model="newClientID"
            class="text-field w-input"
            maxlength="256"
            placeholder="Client ID"
            id="auth_token"
            autocomplete="off"
          />
        </div>
        <div class="col field-col">
          <b-button class="button" @click="addClient()">Add Client</b-button>
        </div>
      </div>
    </div>
    <div class="">
        <label for="webhook_table" class="field-label">Webhook Notifications</label>
<!--        <p>-->
<!--          Webhooks allow your Pro-tekt data to be sent to your server. This can happen in two scenarios.-->
<!--        </p>-->
<!--        <p>-->
<!--          With a <b>custom</b> scoped webhook, only notifications are sent to the webhook. You can precisely control which notifications are sent in the "Notification Rules" tab.-->
<!--            These can be configured per device, and only for some events (for instance, only send overspeed notificatitons for my car).-->
<!--        </p>-->
<!--        <p>-->
<!--          With an <b>events</b> scoped webhook, standard events, including notifications, new trips, and changes to device information, are sent to the webhook. You cannot control which events are sent, however you can configure your receiving server to ignore events of a given type.-->
<!--        </p>-->

        <b-table striped hover :items="webhooks" :fields="webhookFields" v-if="webhooks.length > 0" id="webhook_table">
          <template v-slot:cell(actions)="row">
            <div>
              <i
                class="icon-button"
                :class="$config.icons.general.edit"
                v-b-tooltip.hover
                title="Edit Webhook"
                @click="editWebhook(row.item)"
              ></i>
              <i
                class="icon-button"
                :class="$config.icons.general.sendNotification"
                v-b-tooltip.hover
                title="Send Test Webhook"
                @click="sendTest(row.item)"
              ></i>
              <i
                class="icon-button row-icon-button-danger"
                :class="$config.icons.general.remove"
                v-b-tooltip.hover
                title="Remove/Reset"
                v-if="!row.item.disable_delete"
                @click="deleteWebhook(row.item)"
              ></i>
            </div>
          </template>
        </b-table>
        <div v-if="webhooks.length === 0">You do not have any webhooks set up yet. Set up one using the form below.</div>
<!--        <div class="col field-col">-->
<!--          <label for="webhook_url" class="field-label">Add New Webhook</label>-->
<!--          <input-->
<!--            type="text"-->
<!--            v-model="newWebhookURL"-->
<!--            class="text-field w-input"-->
<!--            maxlength="256"-->
<!--            placeholder="Webhook URL"-->
<!--            id="webhook_url"-->
<!--            autocomplete="off"-->
<!--          />-->
<!--        </div>-->

<!--        <div class="col field-col">-->
<!--          <label for="user_name" class="field-label">Select Webhook Type</label>-->
<!--          <b-form-select v-model="newWebhookType" :options="webhookTypes"-->
<!--                        aria-placeholder="Select Webhook Type"-->
<!--          ></b-form-select>-->
<!--        </div>-->
<!--        <div class="col field-col">-->
<!--          <label for="webhook_headers" class="field-label">Specify Headers as {key:value} JSON</label>-->
<!--          <input-->
<!--            type="text"-->
<!--            v-model="newWebhookHeaders"-->
<!--            class="text-field w-input"-->
<!--            maxlength="1024"-->
<!--            placeholder="Webhook URL"-->
<!--            id="webhook_headers"-->
<!--            autocomplete="off"-->
<!--          />-->
<!--        </div>-->
        <div class="col field-col">
          <b-button class="button" @click="addWebhook()">Add Webhook</b-button>
        </div>
      </div>
<!--    Edit Webhook -->
    <b-modal
      id="modal-webhook-edit"
      centered
      class="modal-content"
      size="xl"
      hide-footer
      title="Command Help"
    >
      <edit-webhook modal-id="modal-webhook-edit" :webhook="selectWebhook" @save="loadWebhooks"></edit-webhook>
    </b-modal>
    </div>
</template>

<script>
import * as DataProvider from './helpers/DataProvider'
// import * as EventService from './helpers/EventService'
import * as ErrorHelper from './helpers/ErrorHelper'
import LoadingBox from './helpers/LoadingBox'
import EditWebhook from '@/components/EditWebhook'
import {
  minLength,
  required,
  maxLength,
  email
} from 'vuelidate/lib/validators'
import * as AlertHelper from '@/components/helpers/AlertHelper'

export default {
  name: 'edit-user-preferences',
  components: { LoadingBox, EditWebhook },
  props: {
    isModal: Boolean,
    modal: String,
    user: Object
  },
  data: function () {
    return {
      loading: true,
      newClientID: '',
      newWebhookURL: '',
      newWebhookType: 'custom',
      newWebhookHeaders: '{}',
      selectWebhook: null,
      oauthTokens: [],
      oauthFields: [
        { key: 'client_id', label: 'Client ID', sortable: true },
        { key: 'client_secret', label: 'Client Secret', sortable: true },
        { key: 'scope', label: 'Scope', sortable: true },
        { key: 'actions', label: 'Actions' }
      ],
      webhooks: [],
      webhookFields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'webhook_url', label: 'URL', sortable: true },
        { key: 'webhook_type', label: 'Webhook Type', sortable: true, formatter: this.formatWebhookTypes },
        { key: 'status', label: 'Status', formatter: this.formatWebhookState },
        { key: 'actions', label: 'Actions' }
      ],
      webhookStates: {
        0: 'Disabled',
        1: 'Active',
        2: 'Error (Disabled)'
      },
      webhookTypes: {
        0: 'Custom',
        1: 'Events'
      }
    }
  },

  async created () {
    this.loadUserClients()
    this.loadWebhooks()
  },
  methods: {
    loadUserClients: async function () {
      let response = await DataProvider.getUserAPIClients()
      console.log('User API Clients: ', response)
      if (response.success) {
        this.oauthTokens = response.data['clients']
      } else {
        ErrorHelper.displayDataErrorToast(response)
      }
      this.loading = false
    },
    addClient: async function () {
      let res = await DataProvider.addUserAPIClient(this.newClientID)
      if (res.success) {
        this.$bvToast.toast('The changes you made have been saved!', {
          title: 'Changes Saved',
          variant: 'success',
          toaster: 'b-toaster-top-center'
        })
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
      this.loadUserClients()
    },
    revokeClient: async function (key) {
      let res = await DataProvider.revokeUserAPIClient(key.client_secret)
      if (res.success) {
        this.$bvToast.toast('The changes you made have been saved!', {
          title: 'Changes Saved',
          variant: 'success',
          toaster: 'b-toaster-top-center'
        })
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
      this.loadUserClients()
    },
    loadWebhooks: async function () {
      let response = await DataProvider.getUserWebhooks()
      console.log('Webhooks: ', response)
      if (response.success) {
        this.webhooks = response.data['webhooks']
      } else {
        ErrorHelper.displayDataErrorToast(response)
      }
      this.loading = false
    },
    editWebhook: function (webhook) {
      this.selectWebhook = webhook
      this.$bvModal.show('modal-webhook-edit')
    },
    addWebhook: function () {
      this.selectWebhook = null
      this.$bvModal.show('modal-webhook-edit')
    },
    sendTest: async function (webhook) {
      let res = await DataProvider.sendTestWebhook(webhook.id)
      if (res.success) {
        AlertHelper.successToast('Test Message Send', 'Test Webhook notification queued for sending.')
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    deleteWebhook: async function (key) {
      let res = await DataProvider.deleteWebhook(key.id)
      if (res.success) {
        this.$bvToast.toast('The changes you made have been saved!', {
          title: 'Changes Saved',
          variant: 'success',
          toaster: 'b-toaster-top-center'
        })
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
      this.loadWebhooks()
      // Reload config events
      // await EventService.refreshConfigData()
    },
    formatWebhookState: function (stateInt) {
      if (this.webhookStates.hasOwnProperty(stateInt)) {
        return this.webhookStates[stateInt]
      } else {
        return 'Unknown'
      }
    },
    formatWebhookTypes: function (typeInt) {
      if (this.webhookTypes.hasOwnProperty(typeInt)) {
        return this.webhookTypes[typeInt]
      } else {
        return 'Unknown'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../variables";

.edit-user-preferences {
  color: $text-color-invert;
  padding: 20px;
}

.button {
  margin: 0px 5px;
}

.field-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.field-col {
  min-width: fit-content;
}

.field-label {
  font-family: "Open Sans", sans-serif;
  color: $theme-color-primary-3;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
}

.footer {
  width: 100%;
  bottom: 2%;
  display: flex;
  justify-content: flex-end;
  margin: 0 1% 0 1%;
  flex-wrap: wrap;
}

@media screen and (max-width: 600px) {
  .footer {
    justify-content: space-around;
    margin: 0;
  }
  .button {
    margin-top: 5%;
  }
}
</style>
