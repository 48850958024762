var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "preferences" },
    [
      _c(
        "b-card",
        { staticClass: "pref-card" },
        [
          _c("b-card-header", [_vm._v("User Preferences")]),
          _c(
            "b-card-body",
            [
              _c(
                "b-tabs",
                {
                  model: {
                    value: _vm.activeTab,
                    callback: function($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab"
                  }
                },
                [
                  _c("b-tab", { attrs: { title: "Preferences" } }, [
                    _c(
                      "div",
                      { staticClass: "base-card" },
                      [_c("edit-user-preferences")],
                      1
                    )
                  ]),
                  _c("b-tab", { attrs: { title: "Notification Rules" } }, [
                    _c(
                      "div",
                      { staticClass: "base-card" },
                      [_c("edit-user-notifications")],
                      1
                    )
                  ]),
                  _c("b-tab", { attrs: { title: "Subscription" } }, [
                    _c(
                      "div",
                      { staticClass: "base-card" },
                      [_c("edit-user-subscription")],
                      1
                    )
                  ]),
                  _c("b-tab", { attrs: { title: "App Download" } }, [
                    _c("div", { staticClass: "base-card app-card" }, [
                      _c("div", [
                        _vm._v(
                          " You can use the below download links to access the Pro-Tekt GPS Tracking App for both Android and Apple devices. "
                        )
                      ]),
                      _c("div", { staticClass: "app-link" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://play.google.com/store/apps/details?id=au.com.pro_tekt.ios&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                            }
                          },
                          [
                            _c("img", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                alt: "Get it on Google Play",
                                src: require("@/assets/google-play-badge.png")
                              }
                            })
                          ]
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "app-link",
                          staticStyle: { padding: "15px" }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://apps.apple.com/us/app/pro-tekt/id1230764414?itsct=apps_box&itscg=30200"
                              }
                            },
                            [
                              _c("img", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  src: require("@/assets/itunes-black.svg"),
                                  alt: "Download on the App Store"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ])
                  ]),
                  _vm.$auth.hasRole("fleet_user")
                    ? _c("b-tab", { attrs: { title: "Reports" } }, [
                        _c(
                          "div",
                          { staticClass: "base-card" },
                          [_c("edit-user-report-preferences")],
                          1
                        )
                      ])
                    : _vm._e(),
                  _c("b-tab", { attrs: { title: "API Access" } }, [
                    _c(
                      "div",
                      { staticClass: "base-card" },
                      [_c("user-api-access")],
                      1
                    )
                  ]),
                  _c("b-tab", { attrs: { title: "Help" } }, [
                    _c(
                      "div",
                      { staticClass: "base-card" },
                      [_c("user-help")],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }