var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-user-preferences flex-column" },
    [
      _c("LoadingBox", { attrs: { loading: _vm.loading } }),
      _c("h4", { staticClass: "mt-0 mb-0" }, [_vm._v("User Profile")]),
      _c("div", { staticClass: "green-divider" }),
      !_vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "flex-row flex-grow-1 justify-content-between align-content-start"
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex-column flex-grow-1 align-content-end",
                  class: { "form-error": _vm.$v.userData.name.$error }
                },
                [
                  _c(
                    "label",
                    { staticClass: "field-label", attrs: { for: "user_name" } },
                    [_vm._v("Full Name")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.userData.name.$model,
                        expression: "$v.userData.name.$model"
                      }
                    ],
                    staticClass: "w-100",
                    attrs: {
                      type: "text",
                      maxlength: "256",
                      placeholder: "Full Name",
                      id: "user_name"
                    },
                    domProps: { value: _vm.$v.userData.name.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.$v.userData.name,
                          "$model",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  !_vm.$v.userData.name.required
                    ? _c("div", { staticClass: "input-error" }, [
                        _vm._v("Field is Required")
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "flex-column flex-grow-1 ml-2",
                  class: { "form-error": _vm.$v.userData.email.$error }
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "field-label",
                      attrs: { for: "email_address" }
                    },
                    [_vm._v("Email")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.userData.email.$model,
                        expression: "$v.userData.email.$model"
                      }
                    ],
                    staticClass: "w-100",
                    attrs: {
                      type: "text",
                      maxlength: "256",
                      placeholder: "Email Address",
                      id: "email_address"
                    },
                    domProps: { value: _vm.$v.userData.email.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.$v.userData.email,
                          "$model",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  !_vm.$v.userData.email.required
                    ? _c("div", { staticClass: "input-error" }, [
                        _vm._v("Field is Required")
                      ])
                    : _vm._e(),
                  !_vm.$v.userData.email.email
                    ? _c("div", { staticClass: "input-error" }, [
                        _vm._v("Must be a valid email address")
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "flex-column flex-grow-1 ml-2",
                  class: { "form-error": _vm.$v.userData.mobile.$error }
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "field-label",
                      attrs: { for: "Registration" }
                    },
                    [_vm._v("Mobile Number")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.userData.mobile.$model,
                        expression: "$v.userData.mobile.$model"
                      }
                    ],
                    staticClass: "w-100",
                    attrs: {
                      type: "text",
                      maxlength: "256",
                      name: "Registration",
                      "data-name": "Registration",
                      placeholder: "0000 000 000",
                      id: "Registration"
                    },
                    domProps: { value: _vm.$v.userData.mobile.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.$v.userData.mobile,
                          "$model",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  !_vm.$v.userData.mobile.required
                    ? _c("div", { staticClass: "input-error" }, [
                        _vm._v("Field is Required")
                      ])
                    : _vm._e(),
                  !_vm.$v.userData.mobile.minLength ||
                  !_vm.$v.userData.email.maxLength
                    ? _c("div", { staticClass: "input-error" }, [
                        _vm._v("Must be 10 characters long")
                      ])
                    : _vm._e()
                ]
              )
            ]
          )
        : _vm._e(),
      _c("h4", { staticClass: "mt-4 mb-0" }, [_vm._v("Interface Settings")]),
      _c("div", { staticClass: "green-divider" }),
      !_vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "flex-row flex-grow-1 justify-content-between align-content-start"
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex-column flex-grow-1 ml-2",
                  class: { "form-error": _vm.$v.userData.mobile.$error }
                },
                [
                  _c(
                    "b-checkbox",
                    {
                      attrs: { switch: "", checked: _vm.showParkedDetails },
                      on: {
                        change: function($event) {
                          return _vm.changeSetting(
                            !_vm.showParkedDetails,
                            "showParkedLocations"
                          )
                        }
                      }
                    },
                    [_vm._v("Show Parked Details")]
                  ),
                  !_vm.$v.userData.mobile.required
                    ? _c("div", { staticClass: "input-error" }, [
                        _vm._v("Field is Required")
                      ])
                    : _vm._e(),
                  !_vm.$v.userData.mobile.minLength ||
                  !_vm.$v.userData.email.maxLength
                    ? _c("div", { staticClass: "input-error" }, [
                        _vm._v("Must be 10 characters long")
                      ])
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "flex-row mt-2 justify-content-end" }, [
        _c(
          "button",
          {
            staticClass: "button",
            on: {
              click: function($event) {
                return _vm.$bvModal.show("modal-change-password")
              }
            }
          },
          [_vm._v("Change Password")]
        ),
        _c(
          "button",
          {
            staticClass: "button",
            on: {
              click: function($event) {
                return _vm.clickClose()
              }
            }
          },
          [_vm._v("Close")]
        ),
        _c(
          "button",
          {
            staticClass: "button",
            on: {
              click: function($event) {
                return _vm.saveChanges()
              }
            }
          },
          [_vm._v("Save Changes")]
        )
      ]),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-change-password",
            centered: "",
            size: "lg",
            "hide-footer": "",
            title: "Change Password"
          }
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.clickChangePassword.apply(null, arguments)
                }
              }
            },
            [
              _c("div", { staticClass: "field-container" }, [
                _c("div", { staticClass: "property-item" }, [
                  _c(
                    "label",
                    { staticClass: "device-label", attrs: { for: "password" } },
                    [_vm._v("Enter a New Password:")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password"
                      }
                    ],
                    staticClass: "text-field w-input",
                    attrs: {
                      type: "password",
                      maxlength: "256",
                      placeholder: "New Password",
                      id: "password"
                    },
                    domProps: { value: _vm.password },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.password = $event.target.value
                      }
                    }
                  })
                ]),
                _c("div", { staticClass: "property-item" }, [
                  _c(
                    "label",
                    {
                      staticClass: "device-label",
                      attrs: { for: "confirm-password" }
                    },
                    [_vm._v("Retype New Password:")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.passwordConfirm,
                        expression: "passwordConfirm"
                      }
                    ],
                    staticClass: "text-field w-input",
                    attrs: {
                      type: "password",
                      maxlength: "256",
                      placeholder: "Confirm Password",
                      id: "confirm-password"
                    },
                    domProps: { value: _vm.passwordConfirm },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.passwordConfirm = $event.target.value
                      }
                    }
                  })
                ])
              ]),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "button",
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.hide("modal-change-password")
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "b-button",
                    { staticClass: "button", attrs: { type: "submit" } },
                    [_vm._v("Change Password")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }